@font-face {
  font-family: "OpenSans";   /*Can be any text*/
  src: local("OpenSans"),
    url("./fonts/OpenSans 1.ttf") format("truetype");
}
@font-face {
  font-family: "FuturaBook";   /*Can be any text*/
  src: local("FuturaBook"),
    url("./fonts/FuturaBook.ttf") format("truetype");
}

@font-face {
  font-family: "Futura Bold font";   /*Can be any text*/
  src: local("Futura Bold font"),
    url("./fonts/Futura Bold font.ttf") format("truetype");
}

@font-face {
  font-family: "Futura Medium font";   /*Can be any text*/
  src: local("Futura Medium font"),
    url("./fonts/Futura Medium font.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTabs-indicator{
  /*background-color: #FFF227 !important;*/
  background-color: #8FF8FF !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}