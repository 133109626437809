#dialogLoading .MuiDialog-paper {
    margin: 48px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    height: auto;
    box-shadow: 0 0 0 0 !important;
    align-items: center;
}

#dialogLoading h1 {
    color: #FFF;
}

#dialogLoading .MuiDialogProgress-svg {
    color: #FFF;
}