

/* Main content */
#content_wrapper {
    width: 100%;
    position: relative;
    background-color: #b4b4b438;
    /*margin: 0.6em auto 1.1em auto; TODO*/
    padding: 1em;
    /*overflow: auto;*/
}

#content_wrapper h1 {
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
}

#content {
    font-size: 18px;
    text-align: justify;
}

#content button {
    /*color: white;*/
    /*background-color: rgb(197, 134, 52);*/
    text-decoration: none;
    /*border: 1px solid transparent;*/
    margin: 5px;
    background-color: rgb(124, 124, 124);
    text-transform: initial;
    text-align: left;
    font-size: 16px;
}

.optionsButtons {
    /*display: flex;
    justify-content: center;*/
}

.controlPanel {
}

.good-action-box {
    border: 4px solid #4caf50;
    border-radius: 2px;
    background-color: #4caf50;
}

.good-action {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;

}
.bad-action-box {
    border: 4px solid red;
    border-radius: 2px;
    background-color: red;
}

.bad-action {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;

}

.info-action-box {
    border: 4px solid #3f51b5;
    border-radius: 2px;
    background-color: #3f51b5;
}

.info-action {
    color: #fff;
    font-weight: bold;
    /*text-transform: uppercase;*/

}

.no_p {
  /*font-family: "FuturaBook";*/

  font-size: 20px;   
}

.snippet {
  display: block; 
  white-space: pre; 
  border: 1px solid #999;
  background-color:rgb(124, 124, 124); 
  overflow: auto;
}




.typewriter h1 {
    color: gray;
    font-family: monospace;
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */
    animation:
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0
    }

    to {
      width: 100%
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {

    from,
    to {
      border-color: transparent
    }

    50% {
      border-color: orange
    }
  }